import React, { useState } from 'react';

// ButtonComponent: A styled button that displays a random emoji when clicked
const ButtonComponent: React.FC = () => {
  // State to manage the button's text
  const [buttonText, setButtonText] = useState<string>('Click me!');

  // Styles for the button
  const buttonStyle: React.CSSProperties = {
    backgroundColor: 'blue',
    color: 'white',
    borderRadius: '10px',
    padding: '10px 20px',
    fontSize: '16px',
    border: 'none',
    cursor: 'pointer',
    outline: 'none',
  };

  // Array of emojis to choose from
  const emojis: string[] = ['😀', '😂', '🤣', '😊', '😍', '🥳', '🤔', '😎', '🤩', '😄'];

  // Function to generate a random emoji
  const getRandomEmoji = (): string => {
    const randomIndex = Math.floor(Math.random() * emojis.length);
    return emojis[randomIndex];
  };

  // Handle click event
  const handleClick = (): void => {
    const randomEmoji = getRandomEmoji();
    window.alert(`Here's your random emoji: ${randomEmoji}`);
    setButtonText(randomEmoji);
  };

  return (
    <button style={buttonStyle} onClick={handleClick}>
      {buttonText}
    </button>
  );
};

// Main component that integrates the ButtonComponent
const App: React.FC = () => {
  // Styles for centering the button
  const containerStyle: React.CSSProperties = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    width: '100vw',
  };

  return (
    <div style={containerStyle}>
      <ButtonComponent />
    </div>
  );
};

export default App;